
@media only screen and (min-width: 1200px) {
  .height_table_road_map {
      max-height: 380px !important;
    } 
    .vehicle_table_road_map {
      max-height: 250px !important;
    }
  }
  
  @media only screen and (min-width: 1500px) {
    .height_table_road_map {
      max-height: 380px !important;
    } 
    .vehicle_table_road_map {
      max-height: 250px !important;
    }
  }
  
  @media only screen and (min-width: 1800px) {
    .height_table_road_map {
      min-height: 570px !important;
    } 
    .vehicle_table_road_map {
      max-height: 454px !important;
    }
  } 
  
  .vehicle_table {
    width: 100%;
    overflow-y: auto;
    margin-top: 10px !important;
  } 
  
  .vehicle_table_road_map {
    /* max-height: 450px !important; */
    overflow-y: auto;
    margin-top: 10px !important;
  }
  .vehicle_table > .MuiTable-stickyHeader > .MuiTableHead-root >  .MuiTableRow-head > .MuiTableCell-head{
    background: #c62222;
    color: white !important;
    border: 0.5px solid #EBECED;
    font-size: 12px !important;
    padding: 10px !important;
  }
  .vehicle_table_road_map  > .MuiTable-stickyHeader > .MuiTableHead-root >  .MuiTableRow-head > .MuiTableCell-head{
    background: #7c7c7c;
    color: white !important;
    border: 0.5px solid #EBECED;
    font-size: 12px !important;
    padding: 0px !important;
  }
  .table-icon-car {
    /* transform: rotate(269deg); */
  }
  .vehicle_table > .MuiTable-stickyHeader >  .MuiTableBody-root >  .MuiTableRow-root  > .MuiTableCell-root {
    border: 0.5px solid #EBECED;
    font-size: 12px !important;
    padding: 4px !important;
  }
  .vehicle_table_road_map > .MuiTable-stickyHeader >  .MuiTableBody-root >  .MuiTableRow-root  > .MuiTableCell-root {
    border: 0.5px solid #EBECED;
    font-size: 12px !important;
    padding: 4px !important;
  }
  
  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C4C4C4; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
  }
  .spiner {
    width: 30px;
    height: 30px;
  }
  
  .vehicle_table_road_map .MuiTableCell-root {
    padding: 0 !important;
    font-size : 0.75rem !important
  }

.selected_range {
  background: #D9042F !important;
  color: white !important;
}
.header_table {
  margin-top: 1em !important;
}
.menu_video_map{
  height: calc(100vh - 100px);
  padding: 1em;
}

.float-right {
  float: right;
}

.row-active {
  background-color: #FFE5E5 !important;
}

.table_driver{
  border: 1px solid #ebebed;
  padding: 12px;
  border-radius: 5px;
  box-shadow: rgba(30, 30, 30, 0.16) 0px 1px 1px; 
  
}

@media only screen and (min-width: 1200px) {
  .height_table {
      max-height: 380px !important;
    } 
    .vehicle_table_road_map {
      max-height: 220px !important;
    }
  }
  
  @media only screen and (min-width: 1500px) {
    .height_table {
      max-height: 550px !important;
    } 
    .vehicle_table_road_map {
      max-height: 454px !important;
    }
  }
  
  @media only screen and (min-width: 1800px) {
    .height_table {
      min-height: 550px !important;
    } 
    .vehicle_table_road_map {
      max-height: 454px !important;
    }
  } 

.vehicle_table {
  width: 100%;
  overflow-y: auto;
  margin-top: 10px !important;
}
.info_vehicle_pophover { 
  border-radius: 1em;
  background-color: rgb(255, 200, 138);
  padding: 1em;
} 
/* style={{ backgroundColor: 'white', border: '1px solid black', opacity: 0.75, padding: 12 }} */

.map {
  position: relative;
}
.menu_map {
  padding: 1em;
  background-color: white !important;
  width: 300px;
  height: 40px;
  max-height: 90vh !important;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
  overflow-y: auto;
  transition: 'all .3s';
}

.show_menu {
  position: absolute;
  top: 22px;
  left: 0px;
  background-color: white;
  z-index: 1;
  cursor: pointer;
  box-shadow: 'rgb(158 158 158 / 87%) 7px 9px 15px 0px';
  border-radius: '0 8px 8px 0';
  width: '28px';
  height: '48px';
}

.leaflet-control-attribution{
  display: none !important;
}

.get_current_location {
  position: absolute !important;
  bottom: 3%;
  right: 1%;
  background-color: white !important;
  
  cursor: pointer;
  box-shadow: rgba(20, 20, 20, 0.322)  0px 0px 2px 0px;
  border: 0px !important;
  text-transform: none;
  padding: 8px;
  color: red;
  width: 45px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50% !important;
}

.get_current_location > span {
  position: absolute;
  right: 22.5%;
  top: 22%;
}
.state_lite {
  position: absolute !important;
  bottom: 0px;
  left: 0px;
  background-color: white !important;
  z-index: 1;
  cursor: pointer;
  box-shadow:  rgb(158 158 158 / 87%) 7px 9px 15px 0px;
  border: 0px !important;
  padding: 0px !important;
  text-transform: none;
  border-radius: 2px!important;
  height: 38px !important;
}

.show_menu button:hover {
  border-radius: 2px!important;
  background-color: gray !important;

}

.vehicle_table_road_map {
  background-color: transparent !important;
  font-size: 11px !important
}
.vehicle_table_outer_roadmap {
  background-color: transparent !important;
  font-size: 11px !important
}
.license_number_marker {
  background-color: rgb(255, 255, 255);
  padding: 0.2em;
  width: max-content !important;
  border-radius: 0.1em;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-top: 35px;
}

.your_location {
  background-color: rgba(19, 19, 19, 0.493);
  color: white;
  padding: 0.5em;
  border-radius: 5px
}

.license_number_box .gm-ui-hover-effect {
  visibility: hidden;
}
.gm-style-mtc{margin-bottom:20px !important}

.info_vehicle_window {
  padding: 0.1em;
  width: 375px !important;
}



/* style Open Street Map */

.leaflet-right {
  margin-right: '50px !important',
}
.leaflet-bottom {
  bottom: 7px !important;
}
.leaflet-top {
  top: 7px !important;
}

.get_current_location {
  z-index: 999 !important;
} 

.leaflet-routing-container{
  display: none;
}

.leaflet-popup {
  opacity:'0 !important';
}

.leaflet-popup-content-wrapper ::-webkit-scrollbar {
  display: none;
}

.leaflet-popup-content-wrapper {
  /* width: 320px; */
  /* min-height: 330px; */
  /* height: fit-content; */
} 

/* .leaflet-popup-tip {
  background: rgba(0, 0, 0, 0) !important; 
  border: none !important;
} */

/* .tooltip-label {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  margin-top: 50px !important;
  margin-left: 15px;
} */
